import { ChevronLeft, Edit, Settings } from "@mui/icons-material";
import { Box, Button, FormControl, FormLabel, IconButton, Stack, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import useFetch from "src/Components/Common/useFetch";
import { userContext } from "src/Components/Contexts/userContext";
import { UseCaseType } from "src/entity/recon-entity/ReconInterfaces";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import { BalanceConfirmationBetaStatuses } from "src/Utils/PartnerCommunication";
import { ViewEmailThreadRes } from "../../../DisputeResolution/Common/ViewReplyMailThread";
import { EmailInput } from "../../../ReconMIS/AutoCompletes";
import { BcBetaContext } from "../../BalanceConfirmationBeta/BalanceConfirmationBeta";
import { DEFAULT_BRANCH_CODE, DEFAULT_COMPANY_ID } from "../../CommonLegacy/CommonComponents";
import { PartnerCommsContext } from "../../Context/PartnerCommunicationContext";
import { NdAsyncButton } from "../../MsmePartnerPortal/CommonComponents";
import { StateDispatch } from "../CommonComponents";
import { UpdateEmailTemplateDialog, UpdateUserEmailSettingDialog } from "../CommonDialogs";
import EmailEditorWithTemplate from "../EmailEditorWithTemplate";
import { CommunicationTypeDetails, EmailTemplateDetailsForCommsType } from "../PartnerCommsInterfaces";
import { EmailFields } from "../ViewReplyMailThread";

interface SingleMailProps {
  handleBack: () => void;
  handlePageBack: () => void;
  setPassedFns: StateDispatch<{ sendEmail: () => Promise<void> }>;
}

const SingleMailing: React.FC<SingleMailProps> = ({ handleBack, ...props }) => {
  const { actor } = useContext(userContext);
  const { currentRows, branchCode, companyId, listUserEmailTemplates, listAllBusinessPartnersWSR } =
    useContext(BcBetaContext);
  const { emailBusinessPartnerId, communicationTypeId, emailTemplates, emailTemplateContent, userEmailSetting } =
    useContext(PartnerCommsContext);

  // const [userEmailSetting, setUserEmailSetting] = useState<ListUserEmailSettingRes["userEmailSetting"]>({} as any);
  const [commsTypeName, setCommsTypeName] = useState("");
  const [emailTemplateDetails, setEmailTemplateDetails] = useState<EmailTemplateDetailsForCommsType>({} as any);

  const [emailSubject, setEmailSubject] = useState(emailTemplateContent?.emailSubject || "");
  const [editorContent, setEditorContent] = useState(emailTemplateContent?.emailBody || "");
  const [emailTemplateId, setEmailTemplateId] = useState(emailTemplateContent?.templateId || null);
  const [templateError, setTemplateError] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const [toEmails, setToEmails] = useState<string[]>([]);
  const [ccEmails, setCcEmails] = useState<string[]>([]);

  const [openUpdateEmailSettingDlg, setOpenUpdateEmailSettingDlg] = useState(false);

  const emailTemplateName = emailTemplates.find((item) => item.templateId === emailTemplateId)?.templateName;

  // update email Template section
  const [openUpdateEmailTemplate, setOpenUpdateEmailTemplate] = useState(false);

  const ViewEmailThread = () => {
    return useFetch<ViewEmailThreadRes>(API_ENDPOINTS.VIEW_EMAIL_THREAD.url, "GET", {
      failureMessage: API_ENDPOINTS.VIEW_EMAIL_THREAD.failureMessage,
      config: {
        params: {
          companyId: companyId,
          branchCode: branchCode,
          businessPartnerId: emailBusinessPartnerId,
        },
      },
      thenCallBack: (res) => {
        if (res.data) {
          setToEmails(res.data.toEmails || []);
          setCcEmails(res.data.ccEmails || []);
        }
      },
    });
  };

  useEffect(() => {
    ViewEmailThread();
  }, []);

  useEffect(() => {
    GetCommunicationTypeDetail(communicationTypeId);
  }, [communicationTypeId]);

  const handleClose = () => {
    handleBack();
  };

  const GetCommunicationTypeDetail = async (communicationTypeId: number) => {
    return useFetch<{ response: string; data: CommunicationTypeDetails }>(
      API_ENDPOINTS.GET_COMMUNICATION_TYPE_DETAIL.url,
      "GET",
      {
        failureMessage: API_ENDPOINTS.GET_COMMUNICATION_TYPE_DETAIL.failureMessage,
        config: {
          params: {
            communicationTypeId,
          },
        },
        thenCallBack: (_res) => {
          if (_res.data?.data) {
            setCommsTypeName(_res.data.data.name);
            setEmailTemplateDetails(_res.data.data.emailTemplateDetails);
          }
        },
      }
    );
  };

  const checkIfUserEmailTemplateHasToUpdate = async () => {
    if (emailSubject.trim() === "") {
      setTemplateError(true);
      return;
    } else {
      setTemplateError(false);
    }

    const bodyFormData = new FormData();
    bodyFormData.append("emailBody", editorContent);
    bodyFormData.append("emailSubject", emailSubject);
    bodyFormData.append("templateType", UseCaseType.balanceConfirmationBeta);
    bodyFormData.append("templateName", emailTemplateName);

    return new Promise<void>((resolve, reject) => {
      useFetch(API_ENDPOINTS.CHECK_IF_EMAIL_TEMPLATE_NEEDS_TO_BE_ADDED.url, "POST", {
        failureMessage: API_ENDPOINTS.CHECK_IF_EMAIL_TEMPLATE_NEEDS_TO_BE_ADDED.failureMessage,
        data: bodyFormData,
        config: {
          headers: { "Content-Type": "multipart/form-data" },
        },
        thenCallBack: async (response) => {
          // check response here
          if (response.data.isTemplateUpdated) {
            setOpenUpdateEmailTemplate(true);
            resolve();
            // console.log("so why not open");
          } else {
            await sendEmail();
            resolve();
          }
        },
        catchCallBack: reject,
        errorCallback: reject,
      });
    });
  };

  const updateUserEmailTemplate = async () => {
    setOpenUpdateEmailTemplate(false);

    if (emailSubject.trim() === "") {
      setTemplateError(true);
      return;
    }

    const bodyFormData = new FormData();
    bodyFormData.append("receiverId", emailBusinessPartnerId?.toString());
    bodyFormData.append("emailBody", editorContent);
    bodyFormData.append("emailSubject", emailSubject);
    bodyFormData.append("templateName", emailTemplateName);
    bodyFormData.append("templateType", UseCaseType.disputeResolution);
    bodyFormData.append("senderCompanyId", companyId);

    if (files !== null && files.length > 0) {
      for (const item of files) {
        bodyFormData.append("file", item);
      }
    }

    return new Promise<void>((resolve, reject) => {
      useFetch(API_ENDPOINTS.ADD_USER_EMAIL_TEMPLATE.url, "POST", {
        failureMessage: API_ENDPOINTS.ADD_USER_EMAIL_TEMPLATE.failureMessage,
        showSuccessToast: true,
        data: bodyFormData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
        thenCallBack: async () => {
          await sendEmail();

          // reload templates after Adding/Updating User Email Template
          listUserEmailTemplates();

          resolve();

          // props.setLoader(true);
        },
        catchCallBack: reject,
        errorCallback: reject,
      });
    });
  };

  const sendEmail = async () => {
    const EndPointBC = API_ENDPOINTS.SEND_EMAIL.url;

    if (emailSubject.trim() === "") {
      setTemplateError(true);
      return;
    }

    type ExtendedWindow = typeof window & { log?: boolean };

    if ((window as ExtendedWindow)?.log) console.log({ toEmails, ccEmails });

    const bodyFormData = new FormData();
    bodyFormData.append("businessPartnerId", emailBusinessPartnerId?.toString());
    bodyFormData.append("emailSubject", emailSubject);
    bodyFormData.append("emailBody", editorContent);
    bodyFormData.append("companyId", companyId || DEFAULT_COMPANY_ID);
    bodyFormData.append("branchCode", branchCode || DEFAULT_BRANCH_CODE);
    bodyFormData.append("to", toEmails.join(","));
    bodyFormData.append("cc", ccEmails.join(","));
    bodyFormData.append("communicationTypeId", communicationTypeId?.toString());

    if (files !== null && files.length > 0) {
      for (const item of files) {
        bodyFormData.append("file", item);
      }
    }

    return await useFetch(EndPointBC, "POST", {
      failureMessage: API_ENDPOINTS.SEND_EMAIL.failureMessage,
      showSuccessToast: true,
      data: bodyFormData,
      thenCallBack: (_res) => {
        // list new ROWs in Main Table;
        listAllBusinessPartnersWSR();
        handleClose();

        // go back to main page
        props.handlePageBack();
      },
      catchCallBack: (_err) => {
        // setShowLoadingIcon(false);
        // go back to main page
        props.handlePageBack();
      },
    });
  };

  useEffect(() => {
    if (props.setPassedFns) {
      props.setPassedFns({ sendEmail: () => checkIfUserEmailTemplateHasToUpdate() });
    }
  }, [emailBusinessPartnerId, emailSubject, toEmails, ccEmails, editorContent, emailTemplateName, files]);

  return (
    <>
      <Stack gap={2} mb={"90px"}>
        {/* Top Bar */}
        <Box sx={{ bgcolor: "#fff", p: 2, borderRadius: 1 }}>
          <Stack direction="row" spacing={2} className="d_flex center_align_ver_horiz">
            <Typography fontWeight="bold" fontSize={18} color="textPrimary">
              Communication Type:
            </Typography>
            <Typography fontWeight="normal" fontSize={16} color="textSecondary">
              {commsTypeName}
            </Typography>
            <Box flexGrow={1} />
            <Button
              size="small"
              variant="text"
              // disabled={firstFoundCommType && !allRowsMatched}
              onClick={() => {
                handleBack();
              }}
              sx={{ color: "#531c4c" }}
            >
              Change
            </Button>
          </Stack>
        </Box>

        {/* Main Setup Section */}
        <Box component="section" className="emailSetupSection" sx={{ bgcolor: "#fff" }}>
          {/* Setup Email Header Bar */}
          <Box
            className="header_bar space_between"
            alignItems="center"
            p={2}
            sx={{ bgcolor: "#fff", borderBottom: 1, borderColor: "#dedede" }}
          >
            <Typography variant="h6" fontWeight="600">
              Setup Email
            </Typography>
            <IconButton
              size="small"
              onClick={async () => {
                // logic
                const tab = "UserEmailSetting";
                // const authDialog = 1;

                const params = `tab=${tab}`;
                const uri = `/${actor.name}/reconSettings?${params}`;
                window.open(uri, "_blank")?.focus();
                setOpenUpdateEmailSettingDlg(true);
              }}
            >
              <Settings />
            </IconButton>
          </Box>

          <Box
            sx={{
              p: 2,
              bgcolor: "#f8f8f8",
              borderBottom: 1,
              borderColor: "#dedede",
            }}
          >
            <Stack spacing={2}>
              <Box className="d_flex" gap={2}>
                <EmailFields label="From:" emails={[userEmailSetting?.from_email]} isSender />
                <IconButton
                  onClick={async () => {
                    // logic
                    const tab = "UserEmailSetting";
                    const authDialog = 1;

                    const params = `tab=${tab}&authDialog=${authDialog}`;
                    const uri = `/${actor.name}/reconSettings?${params}`;
                    window.open(uri, "_blank")?.focus();
                    setOpenUpdateEmailSettingDlg(true);
                  }}
                >
                  <Edit />
                </IconButton>
              </Box>

              <FormControl>
                <Box display={"inline-flex"} gap={4} alignItems="center">
                  <FormLabel className="" sx={{ width: 40 }}>
                    To :
                  </FormLabel>
                  <EmailInput emails={toEmails} setEmails={setToEmails} label="" limitTags={3} sx={{ width: "100%" }} />
                </Box>
              </FormControl>

              <FormControl>
                <Box display={"inline-flex"} gap={4} alignItems="center">
                  <FormLabel className="" sx={{ width: 40 }}>
                    CC :
                  </FormLabel>
                  <EmailInput emails={ccEmails} setEmails={setCcEmails} label="" limitTags={3} sx={{ width: "100%" }} />
                </Box>
              </FormControl>
            </Stack>
          </Box>

          <EmailEditorWithTemplate
            inputEditorState={{ emailTemplateId: emailTemplateDetails.id, templateError: templateError }}
            returnEditorState={(emailSubject, editorContent, templateId, files) => {
              setEmailSubject(emailSubject);
              setEditorContent(editorContent);
              setEmailTemplateId(templateId);

              setFiles(files);
            }}
            sectionsCustomizations={{
              subject:
                currentRows[0] && currentRows[0].status === BalanceConfirmationBetaStatuses.RequestBalanceConfirmation,
            }}
            otherProps={{
              branchCode: branchCode,
              companyId: companyId,
              listUserEmailTemplates: listUserEmailTemplates,
              templateType: UseCaseType.disputeResolution,
            }}
          />
        </Box>
      </Stack>

      {/* Bottom Navigation Bar */}
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          left: -2,
          width: "100%",
          boxShadow: "0px -2px 4px 0px rgba(0, 0, 0, 0.08)",
          background: "#fff",
        }}
      >
        <Stack
          direction={"row"}
          sx={{
            justifyContent: "space-between",
            maxWidth: "900px",
            marginX: "auto",
            padding: "24px",
          }}
        >
          <Stack sx={{ display: "flex", flexDirection: "row", gap: "16px" }}>
            <Button color="inherit" onClick={handleBack} startIcon={<ChevronLeft />}>
              Back
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />

            <NdAsyncButton
              className="theme_btn"
              buttonComponent={Button}
              onClick={async () => {
                await checkIfUserEmailTemplateHasToUpdate();
              }}
            >
              Send Email
            </NdAsyncButton>
          </Stack>
        </Stack>
      </Box>

      {/* Update Email Template Dialog */}
      <UpdateEmailTemplateDialog
        open={openUpdateEmailTemplate}
        setOpen={setOpenUpdateEmailTemplate}
        yesAction={async () => {
          await updateUserEmailTemplate();
        }}
        noAction={async () => {
          await sendEmail();
        }}
      />
      {/* Update User Email Settings Dialog */}
      <UpdateUserEmailSettingDialog open={openUpdateEmailSettingDlg} setOpen={setOpenUpdateEmailSettingDlg} />
    </>
  );
};

export default SingleMailing;
