import { Autocomplete, Button, Checkbox, FormControlLabel, TextField } from "@mui/material";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import MonetaryInput from "src/Components/Common/MonetaryInput";
import uiLogger from "src/Utils/UiLogger";
import { BusinessPartnersListForBalanceConfirmation } from "../../../../entity/recon-entity/ReconInterfaces";
import { uiLoggerName } from "../../../../Utils/Recon/UiLogger/Constants";
import { TagsInputUniqueKeys } from "../../ReconMIS/AutoCompletes";
import { CheckboxGroup } from "../../ReconMIS/utils";
import { TallyOrSapCompanyId } from "../CommonLegacy/CommonComponents";
import "../Styles/LedgerRequestFilter.scss";

type StateDispatch<T = any> = React.Dispatch<React.SetStateAction<T>>;

type setAppliedFilters = StateDispatch<{
  [key: string]: string;
}>;

interface BalanceConfirmationFilterProps {
  allCategories: string[];
  storeRowsDataBalanceConfirmation: BusinessPartnersListForBalanceConfirmation[];
  rowsDataBalanceConfirmation: BusinessPartnersListForBalanceConfirmation[];
  setRowsDataBalanceConfirmation: StateDispatch<BusinessPartnersListForBalanceConfirmation[]>;
  setAppliedFilters: setAppliedFilters;
  setIsFilterAppliedBalanceConfirmation: StateDispatch<boolean>;
  openBalanceConfirmationFilter: boolean;
  setOpenBalanceConfirmationFilter: StateDispatch<boolean>;
  reFilter: boolean;
  setReFilter: StateDispatch<boolean>;
  companyId: TallyOrSapCompanyId;
  branchCode: string;
}
const BalanceConfirmationFilter = (props: BalanceConfirmationFilterProps) => {
  const [selectedCategory, setSelectedCategory] = useState<string[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<string[]>([]);
  const [fromDateAsOfLastStatusChange, setFromDateAsOfLastStatusChange] = useState<string>(null);
  const [toDateAsOfLastStatusChange, setToDateAsOfLastStatusChange] = useState<string>(null);
  const [minClosingBalance, setMinClosingBalance] = useState<number>(null);
  const [maxClosingBalance, setMaxClosingBalance] = useState<number>(null);
  const [fromDateOfClosingBalance, setFromDateOfClosingBalance] = useState<string>(null);
  const [toDateOfClosingBalance, setToDateOfClosingBalance] = useState<string>(null);
  const [fromDateAsOfLastBalanceConfirmed, setFromDateAsOfLastBalanceConfirmed] = useState<string>(null);
  const [toDateAsOfLastBalanceConfirmed, setToDateAsOfLastBalanceConfirmed] = useState<string>(null);
  const [partnerNames, setPartnerNames] = useState<string[]>([]);
  const [downloadAvailable, setDownloadAvailable] = useState({
    yes: false,
    no: false,
  });
  const BalanceConfirmationStatus = [
    "Balance confirmation requested",
    "Check partner reply",
    "Reminder sent",
    "Configuration Underway",
    "Request balance confirmation",
    "Download ledger",
    "Balance confirmed",
    "Send Reminder",
    "Recon shared with Partner",
  ];

  type keys = "partnerNames" | "category" | "status";

  const [filterObj, setFilterObj] = useState<{ [k in keys]: any }>(null);

  const handleClose = () => {
    props.setOpenBalanceConfirmationFilter(false);
  };

  const filterBalanceConfirmation = () => {
    let tempRowData = props.storeRowsDataBalanceConfirmation;

    if (partnerNames?.length) {
      tempRowData = tempRowData?.filter((partnerRow) => {
        let found = false;
        partnerNames.forEach((name, _ind) => {
          if (_ind === 0) found = false;
          const partnerNameWithoutMultiSpace = name.replace(/\s+/g, " ");
          const partnerNameStr = partnerNameWithoutMultiSpace.toLowerCase();
          props.setIsFilterAppliedBalanceConfirmation(true);
          if (partnerRow.businessPartnerName?.replace(/\s+/g, " ").toLowerCase()?.includes(partnerNameStr?.trim())) {
            found = true;
          }
        });
        return found;
      });
    }

    if (selectedCategory.length > 0) {
      props.setIsFilterAppliedBalanceConfirmation(true);
      tempRowData = tempRowData?.filter((item) => {
        return selectedCategory?.every((val) => item.category?.includes(val));
      });
    }
    if (selectedStatus.length > 0) {
      props.setIsFilterAppliedBalanceConfirmation(true);
      tempRowData = tempRowData?.filter((item) => {
        return selectedStatus?.some((val) =>
          item.balanceConfirmationStatus?.toLowerCase()?.includes(val?.toLowerCase())
        );
      });
    }

    if (downloadAvailable.yes || downloadAvailable.no) {
      props.setIsFilterAppliedBalanceConfirmation(true);
      tempRowData = tempRowData?.filter((item) => {
        if (downloadAvailable.yes && !downloadAvailable.no) return item.downloadLedgerKey;
        if (downloadAvailable.no && !downloadAvailable.yes) return !item.downloadLedgerKey;
        else if (downloadAvailable.no && downloadAvailable.yes) return true;
        else return false;
      });
    }

    if (fromDateAsOfLastStatusChange && toDateAsOfLastStatusChange) {
      props.setIsFilterAppliedBalanceConfirmation(true);
      tempRowData = tempRowData?.filter((item) => {
        return (
          moment(item.dateOfLastBalanceConfirmationStatusChange)?.format("YYYY-MM-DD") <= toDateAsOfLastStatusChange &&
          moment(item.dateOfLastBalanceConfirmationStatusChange)?.format("YYYY-MM-DD") >= fromDateAsOfLastStatusChange
        );
      });
    }
    if (fromDateAsOfLastStatusChange && toDateAsOfLastStatusChange === null) {
      props.setIsFilterAppliedBalanceConfirmation(true);
      tempRowData = tempRowData?.filter((item) => {
        return (
          moment(item.dateOfLastBalanceConfirmationStatusChange)?.format("YYYY-MM-DD") >= fromDateAsOfLastStatusChange
        );
      });
    }
    if (fromDateAsOfLastStatusChange === null && toDateAsOfLastStatusChange) {
      props.setIsFilterAppliedBalanceConfirmation(true);
      tempRowData = tempRowData?.filter((item) => {
        return (
          moment(item.dateOfLastBalanceConfirmationStatusChange)?.format("YYYY-MM-DD") <= toDateAsOfLastStatusChange
        );
      });
    }

    if (minClosingBalance !== null && maxClosingBalance !== null) {
      props.setIsFilterAppliedBalanceConfirmation(true);
      tempRowData = tempRowData?.filter((item) => {
        return (
          Number(item.closingBalance) <= Number(maxClosingBalance) &&
          Number(item.closingBalance) >= Number(minClosingBalance)
        );
      });
    }
    if (minClosingBalance !== null && maxClosingBalance === null) {
      props.setIsFilterAppliedBalanceConfirmation(true);
      tempRowData = tempRowData?.filter((item) => {
        return Number(item.closingBalance) >= Number(minClosingBalance);
      });
    }
    if (minClosingBalance === null && maxClosingBalance !== null) {
      props.setIsFilterAppliedBalanceConfirmation(true);
      tempRowData = tempRowData?.filter((item) => {
        return Number(item.closingBalance) <= Number(maxClosingBalance);
      });
    }

    if (fromDateOfClosingBalance && toDateOfClosingBalance) {
      props.setIsFilterAppliedBalanceConfirmation(true);
      tempRowData = tempRowData?.filter((item) => {
        if (item.dateOfClosingBalance) {
          return (
            moment(item.dateOfClosingBalance)?.format("YYYY-MM-DD") <= toDateOfClosingBalance &&
            moment(item.dateOfClosingBalance)?.format("YYYY-MM-DD") >= fromDateOfClosingBalance
          );
        }
        return false;
      });
    }
    if (fromDateOfClosingBalance && toDateOfClosingBalance === null) {
      props.setIsFilterAppliedBalanceConfirmation(true);
      tempRowData = tempRowData?.filter((item) => {
        if (item.dateOfClosingBalance) {
          return moment(item.dateOfClosingBalance)?.format("YYYY-MM-DD") >= fromDateOfClosingBalance;
        }
        return false;
      });
    }
    if (fromDateOfClosingBalance === null && toDateOfClosingBalance) {
      props.setIsFilterAppliedBalanceConfirmation(true);
      tempRowData = tempRowData?.filter((item) => {
        if (item.dateOfClosingBalance) {
          return moment(item.dateOfClosingBalance)?.format("YYYY-MM-DD") <= toDateOfClosingBalance;
        }
        return false;
      });
    }
    if (fromDateAsOfLastBalanceConfirmed && toDateAsOfLastBalanceConfirmed) {
      props.setIsFilterAppliedBalanceConfirmation(true);
      tempRowData = tempRowData?.filter((item) => {
        return (
          moment(item.latestBalanceConfirmedOn)?.format("YYYY-MM-DD") <= toDateAsOfLastBalanceConfirmed &&
          moment(item.latestBalanceConfirmedOn)?.format("YYYY-MM-DD") >= fromDateAsOfLastBalanceConfirmed
        );
      });
    }
    if (fromDateAsOfLastBalanceConfirmed && toDateAsOfLastBalanceConfirmed === null) {
      props.setIsFilterAppliedBalanceConfirmation(true);
      tempRowData = tempRowData?.filter((item) => {
        return moment(item.latestBalanceConfirmedOn)?.format("YYYY-MM-DD") >= fromDateAsOfLastBalanceConfirmed;
      });
    }
    if (fromDateAsOfLastBalanceConfirmed === null && toDateAsOfLastBalanceConfirmed) {
      props.setIsFilterAppliedBalanceConfirmation(true);
      tempRowData = tempRowData?.filter((item) => {
        return moment(item.latestBalanceConfirmedOn)?.format("YYYY-MM-DD") <= toDateAsOfLastBalanceConfirmed;
      });
    }

    setFilterObj({ partnerNames: partnerNames, category: selectedCategory, status: selectedStatus });

    props.setAppliedFilters({
      "Partner Names": partnerNames?.map((name) => name?.trim()).join(", "),
      "Selected Category": selectedCategory.join(","),
      "Selected Status": selectedStatus.join(", "),
      "File Attached":
        downloadAvailable.yes && downloadAvailable.no
          ? "Yes, No"
          : downloadAvailable.yes
          ? "Yes"
          : downloadAvailable.no
          ? "No"
          : "",
      "Closing Balance":
        minClosingBalance !== null && maxClosingBalance !== null
          ? `${minClosingBalance.toLocaleString("en-IN")} - ${maxClosingBalance.toLocaleString("en-IN")}`
          : minClosingBalance !== null
          ? `Minimum: ${minClosingBalance.toLocaleString("en-IN")}`
          : maxClosingBalance !== null
          ? `Maximum: ${maxClosingBalance.toLocaleString("en-IN")}`
          : "",
      "Date As of Last Status Change":
        fromDateAsOfLastStatusChange && toDateAsOfLastStatusChange
          ? [fromDateAsOfLastStatusChange, toDateAsOfLastStatusChange].join(" - ")
          : fromDateAsOfLastStatusChange
          ? `${fromDateAsOfLastStatusChange} Onwards`
          : toDateAsOfLastStatusChange
          ? `Till ${toDateAsOfLastStatusChange}`
          : "",
      "Date of Closing balance":
        fromDateOfClosingBalance && toDateOfClosingBalance
          ? [fromDateOfClosingBalance, toDateOfClosingBalance].join(" - ")
          : fromDateOfClosingBalance
          ? `${fromDateOfClosingBalance} Onwards`
          : toDateOfClosingBalance
          ? `Till ${toDateOfClosingBalance}`
          : "",
      "Date As of Last Balance Confirmed":
        fromDateAsOfLastBalanceConfirmed && toDateAsOfLastBalanceConfirmed
          ? [fromDateAsOfLastBalanceConfirmed, toDateAsOfLastBalanceConfirmed].join(" - ")
          : fromDateAsOfLastBalanceConfirmed
          ? `${fromDateAsOfLastBalanceConfirmed} Onwards`
          : toDateAsOfLastBalanceConfirmed
          ? `Till ${toDateAsOfLastBalanceConfirmed}`
          : "",
    });

    props.setRowsDataBalanceConfirmation(tempRowData);
    handleClose();
  };

  useEffect(() => {
    if (props.reFilter) {
      filterBalanceConfirmation();
      props?.setReFilter(false);
    }
    // eslint-disable-next-line
  }, [props.reFilter]);

  const clearAllAppliedFilters = () => {
    setSelectedCategory([]);
    setSelectedStatus([]);
    setFromDateAsOfLastStatusChange(null);
    setToDateAsOfLastStatusChange(null);
    setMinClosingBalance(null);
    setMaxClosingBalance(null);
    setFromDateOfClosingBalance(null);
    setToDateOfClosingBalance(null);
    setFromDateAsOfLastBalanceConfirmed(null);
    setToDateAsOfLastBalanceConfirmed(null);
    setPartnerNames([]);
    setDownloadAvailable({ yes: false, no: false });

    props.setAppliedFilters({});

    handleClose();
    setTimeout(() => {
      props.setOpenBalanceConfirmationFilter(true);
      props.setRowsDataBalanceConfirmation(props.storeRowsDataBalanceConfirmation);
      props.setIsFilterAppliedBalanceConfirmation(false);
    }, 700);
  };

  return (
    <div>
      <Dialog
        open={props.openBalanceConfirmationFilter}
        aria-labelledby="BalanceConfirmationFilter"
        className="ledgerRequestFilter"
      >
        <DialogTitle className="dialog_header">
          <p className="fs_24">Apply Filter</p>
          <button
            className="delete ml_20"
            aria-label="close"
            onClick={() => {
              setPartnerNames(filterObj?.partnerNames || []);
              setSelectedCategory(filterObj?.category || []);
              setSelectedStatus(filterObj?.status || []);
              handleClose();
            }}
          />
        </DialogTitle>
        <DialogContent>
          <div>
            <div className="d_flex m_15">
              <div className="w_300 vertical_center_align fw_600">Partner Names :</div>
              <div className="w_400 d_flex category_textFeild_width_390 ">
                <TagsInputUniqueKeys<BusinessPartnersListForBalanceConfirmation>
                  tags={partnerNames}
                  setTags={setPartnerNames}
                  className="partnerNamesAutocomplete"
                  fullWidth={true}
                  label=""
                  placeholder="Newline separated Names"
                  splitter="newline"
                  limitTags={3}
                  textFieldProps={{ multiline: true, maxRows: 4 }}
                  options={props.storeRowsDataBalanceConfirmation}
                  accessor={"businessPartnerName"}
                  uniqueKeyAccessor={"businessPartnerId"}
                />
              </div>
            </div>
            <div className="d_flex m_15 ">
              <div className="w_300 vertical_center_align fw_600">Category :</div>
              <div className="w_400 category_textFeild_width_390">
                <Autocomplete
                  // limitTags={3}
                  value={selectedCategory}
                  fullWidth={true}
                  onChange={(_e, value) => {
                    setSelectedCategory(value);
                  }}
                  size="small"
                  multiple={true}
                  id="tags-outlined"
                  options={props.allCategories || []}
                  getOptionLabel={(option) => option}
                  // defaultValue={[top100Films[13]]}
                  filterSelectedOptions={true}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select category"
                      // placeholder="Favorites"
                    />
                  )}
                />
              </div>
            </div>
            <div className="d_flex m_15 ">
              <div className="w_300 vertical_center_align fw_600">Status :</div>
              <div className="w_400 category_textFeild_width_390">
                <Autocomplete
                  // limitTags={3}
                  value={selectedStatus}
                  fullWidth={true}
                  onChange={(_, value) => {
                    setSelectedStatus(value);
                  }}
                  size="small"
                  multiple={true}
                  id="tags-outlined"
                  options={BalanceConfirmationStatus || []}
                  getOptionLabel={(option) => option}
                  // defaultValue={[top100Films[13]]}
                  filterSelectedOptions={true}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select status"
                      // placeholder="Favorites"
                    />
                  )}
                />
              </div>
            </div>

            <div className="d_flex m_15 ">
              <div className="w_300 vertical_center_align fw_600">File attached by Partner:</div>
              <div className="w_400 category_textFeild_width_390">
                <CheckboxGroup row={true} onChange={(_e, v) => setDownloadAvailable({ yes: v.yes, no: v.no })}>
                  <FormControlLabel control={<Checkbox checked={downloadAvailable.yes} name="yes" />} label="Yes" />
                  <FormControlLabel control={<Checkbox checked={downloadAvailable.no} name="no" />} label="No" />
                </CheckboxGroup>
              </div>
            </div>

            <div className="d_flex m_15">
              <div className="w_300 vertical_center_align fw_600">Date as of last status change :</div>
              <div className="w_400 d_flex textFeild_width_185">
                <TextField
                  className="mr_20"
                  label="From"
                  InputLabelProps={{ shrink: true }}
                  id="outlined-size-small"
                  size="small"
                  type="date"
                  value={fromDateAsOfLastStatusChange || ""}
                  onChange={(e) => {
                    setFromDateAsOfLastStatusChange(e.target.value);
                  }}
                />
                <TextField
                  label="To"
                  InputLabelProps={{ shrink: true }}
                  id="outlined-size-small"
                  size="small"
                  type="date"
                  value={toDateAsOfLastStatusChange || ""}
                  onChange={(e) => {
                    setToDateAsOfLastStatusChange(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="d_flex m_15">
              <div className="w_300 vertical_center_align fw_600">Closing Balance :</div>
              <div className="w_400 d_flex textFeild_width_185">
                <MonetaryInput
                  className="mr_20"
                  label="Min"
                  size="small"
                  value={minClosingBalance}
                  setValue={(value) => {
                    setMinClosingBalance(value);
                  }}
                  returnNull={true}
                />
                <MonetaryInput
                  label="Max"
                  size="small"
                  value={maxClosingBalance}
                  setValue={(value) => {
                    setMaxClosingBalance(value);
                  }}
                  returnNull={true}
                />
              </div>
            </div>
            <div className="d_flex m_15">
              <div className="w_300 vertical_center_align fw_600">Date of closing balance :</div>
              <div className="w_400 d_flex textFeild_width_185">
                <TextField
                  className="mr_20"
                  label="From"
                  InputLabelProps={{ shrink: true }}
                  id="outlined-size-small"
                  size="small"
                  type="date"
                  value={fromDateOfClosingBalance || ""}
                  onChange={(e) => {
                    setFromDateOfClosingBalance(e.target.value);
                  }}
                />
                <TextField
                  label="To"
                  InputLabelProps={{ shrink: true }}
                  id="outlined-size-small"
                  size="small"
                  type="date"
                  value={toDateOfClosingBalance || ""}
                  onChange={(e) => {
                    setToDateOfClosingBalance(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="d_flex m_15">
              <div className="w_300 vertical_center_align fw_600">Date as of last balance confirmed :</div>
              <div className="w_400 d_flex textFeild_width_185">
                <TextField
                  className="mr_20"
                  label="From"
                  InputLabelProps={{ shrink: true }}
                  id="outlined-size-small"
                  size="small"
                  type="date"
                  value={fromDateAsOfLastBalanceConfirmed || ""}
                  onChange={(e) => {
                    setFromDateAsOfLastBalanceConfirmed(e.target.value);
                  }}
                />
                <TextField
                  label="To"
                  InputLabelProps={{ shrink: true }}
                  id="outlined-size-small"
                  size="small"
                  type="date"
                  value={toDateAsOfLastBalanceConfirmed || ""}
                  onChange={(e) => {
                    setToDateAsOfLastBalanceConfirmed(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions className="dialog_footer space_between">
          <div>
            <Button
              className="theme_outline_btn"
              onClick={() => {
                clearAllAppliedFilters();
              }}
            >
              Clear All
            </Button>
          </div>
          <div>
            <Button
              className="theme_btn"
              id="filterBalanceConfirmation_button"
              onClick={() => {
                filterBalanceConfirmation();
                uiLogger(uiLoggerName.ui_FilterApplied, props.companyId, props.branchCode);
              }}
            >
              Apply
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default BalanceConfirmationFilter;
