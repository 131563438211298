import { FormGroup, SxProps } from "@mui/material";
import React from "react";
import { formatMoney } from "../../../Utils/MoneyUtils";

export const formatNumberINR = (num: number | string) => {
  if (typeof num === "string") return num;
  if (num === null || typeof num === "undefined") {
    return "-"; // or any other fallback value you prefer
  }

  return formatMoney({ amount: Math.round(num), precision: 0, currency: "INR" });
};

interface CheckboxGroupProps {
  onChange: (
    _e: React.FormEvent<HTMLDivElement>,
    v: {
      [checkBoxName: string]: boolean;
    }
  ) => void;
  children: JSX.Element | JSX.Element[];
  row?: boolean;
  sx?: SxProps;
}
export const CheckboxGroup = ({ onChange, children, row, sx }: CheckboxGroupProps) => {
  const ref: React.MutableRefObject<HTMLDivElement> = React.useRef(null);

  const handleChange = (_e: React.FormEvent<HTMLDivElement>) => {
    const values: { [k: string]: boolean } = {};
    ref.current.querySelectorAll("input[type=checkbox]").forEach((cb) => {
      const checkBox = cb as HTMLInputElement;
      values[checkBox.name || checkBox.value] = checkBox.checked;
    });

    onChange(_e, values);
  };
  return (
    <FormGroup row={row} className="CheckBoxGroup" onChange={handleChange} ref={ref} sx={sx}>
      {children}
    </FormGroup>
  );
};
